<template>
    <v-dialog
            v-model="showLogin"
            persistent
            max-width="800"
            :fullscreen="$vuetify.breakpoint.xsOnly"
    >
        <v-card>
            <v-card-title class="text-h5">
                Passwort Änderung
            </v-card-title>
            <v-card-text>
                <v-form>
                    <validation-observer
                            ref="observer"
                            v-slot="{ invalid }"
                    >

                        <form novalidate class="md-layout md-alignment-center-center">
                            <validation-provider
                                    v-slot="{ errors }"
                                    name="password"
                                    rules="required"
                            >
                                <v-text-field
                                        v-model="form.password"
                                        outlined
                                        type="password"
                                        label="aktuelles Passwort"
                                        :error-messages="errors"
                                        required
                                ></v-text-field>
                            </validation-provider>

                            <validation-provider
                                    v-slot="{ errors }"
                                    name="new_password"
                                    rules="required"
                            >
                                <v-text-field
                                        v-model="form.new_password"
                                        outlined
                                        type="password"
                                        label="neues Passwort"
                                        :error-messages="errors"
                                        required
                                ></v-text-field>
                            </validation-provider>

                            <validation-provider
                                    v-slot="{ errors }"
                                    name="new_password_repeat"
                                    rules="required|confirmed:new_password"
                            >
                                <v-text-field
                                        v-model="form.new_password_repeat"
                                        outlined
                                        type="password"
                                        label="neues Passwort wiederholen"
                                        :error-messages="errors"
                                        required
                                ></v-text-field>
                            </validation-provider>

                            <v-btn
                                    color="secondary"
                                    class="mr-4"
                                    elevation="2"
                                    @click="reset"
                            >Speichern
                            </v-btn>

                            <loading :active="isLoading" :is-full-page="false"/>
                        </form>

                    </validation-observer>
                </v-form>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
    import ValidateMixin from '@/mixins/validate'
    import {onLogout} from './../vue-apollo'
    import {changePassword} from './../graphql/user'

    export default {
        mixins: [ValidateMixin],
        data() {
            return {
                isLoading: false,
                showLogin: true,
                form: {
                    password: "",
                    new_password: "",
                    new_password_repeat: "",
                }
            };
        },
        methods: {
            reset() {
                this.$refs['observer'].validate().then(valid => {
                    if (valid) {
                        this.isLoading = true;

                        this.$apollo.mutate({
                            mutation: changePassword,
                            variables: {
                                password: this.form.password,
                                new_password: this.form.new_password,
                            },
                        }).then(({data}) => {
                            if (data.changePassword) {
                                this.$root.$children[0].me = {
                                    user: {}
                                }
                                onLogout(this.$apolloProvider.defaultClient)
                                this.$router.push({name: 'Login'})
                            } else {
                                this.$refs['observer'].setErrors({
                                    'password': 'Wrong credentials',
                                })
                            }
                        }).catch(() => {

                        }).finally(() => {
                            this.isLoading = false
                        })
                    }
                })
            }
        }
    };
</script>

<style lang="scss">
</style>